import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import Customer from '../../components/CustomerList'
import SEO from "../../components/seo"

export default () => (
  <Layout>

    <SEO title="Our Customers are travel companies around the world"
        description="Our solution is subscription based, that means there is cost to purchase the software, no upfront fees or long commitment and absolutely no hidden fees or charges!" 
        pathname="/clients/"
    />
     
    <Hero 
         headerText="Our Customers" 
         subHeaderText="Travel companies of all sizes around the world use the Getlocal solution"
         heroBanner="/img/worldmapwood.jpg"
         /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2>We build strong partnerships with our clients</h2>
            <p>We have a strong and close relationship with our clients who trust us with their most important assets and sales channels. We do our best to help them succeed and build their business in the complicated and competitive world of travel.</p>
        
            <h2 className="title is-size-4 margin-above">Trusted by travel companies in over 15 countries</h2>
            <div className="columns is-multiline is-vcentered">
                <Customer imgSrc="/img/grayline.png" altText="Gray Line" website="https://grayline.is" />
                <Customer imgSrc="/img/flight-centre.png" altText="Flight Centre" website="https://activities.flightcentre.co.za" />
                <Customer imgSrc="/img/boardaboat.png" altText="Board a boat" website="https://boardaboat.com" />
                <Customer imgSrc="/img/daytrip4u.png" altText="Day Trip 4U" website="https://daytrip4u.com" />

                <Customer imgSrc="/img/savethegroom.svg" altText="Save the Groom" website="https://savethegroom.com" />
                <Customer imgSrc="/img/rioadventures.png" altText="Rio Adventures" website="https://rioadventures.com" />
                <Customer imgSrc="/img/resfeber.png" altText="Resfeber Travel" website="https://resfebertravel.com/" />
                <Customer imgSrc="/img/tenerifefun.png" altText="Tenerife Fun" website="https://tenerife.fun/" />

                <Customer imgSrc="/img/rss.png" altText="Reykjavik Sightseeing" website="https://rss.is" />
                <Customer imgSrc="/img/bustravel.png" altText="Bustravel Iceland" website="https://bustravel.is" />
                <Customer imgSrc="/img/airportdirectlogo.jpg" altText="Airport Direct" website="https://airportdirect.is" />
                <Customer imgSrc="/img/mpc.png" altText="Musical Pub Crawl" website="https://musicalpubcrawl.com" />   
                          
            </div>

            <hr />
            <blockquote>
              "We were looking for a full package and a solid out of the box solution that would help us start at a very reasonable cost and allow us to develop fast;
              without training, software hick ups and outdated bugs. We wanted a B2C and a B2B solution with marketing and sales elements that would meet our exact needs; not close to them.  
              <br /><br />
              The GetLocal solution helped us reach our deadlines (and our launch plan) in a very fast and efficient way. The solution we have is simple, effective and is really core technology that has met our needs.
              <br /><br />
              The team worked closely with us and we strongly feel they back up and care for DayTrip4U, so we develop our business with them aside.
              Long working hours, weekend buzzes and messenger texts were always received with a smile and the team is flexible and helpful.
              <br /><br />
              Our needs are large, we think big and we feel lucky working with GetLocal’s development team as they are second to none.  
              <br /><br />
              We found solutions also to problems that were not into their 2dos list for us, from custom API integrations,  to reviews solution abilities 
              and from search engine hints to e-commerce platform modifications."
              <br /><br />
              — Manos Papyrakis, Co - Founder TripGiant / <a href="https://daytrip4u.com" target="_blank" rel="noopener noreferrer">DayTrip4U</a>
            </blockquote>
            <hr />

            <div className="columns is-multiline is-vcentered">
                
                <Customer imgSrc="/img/moafrika.png" altText="Mo Afrika Tours" website="https://moafrikatours.com" />
                <Customer imgSrc="/img/icelandguidedtours.png" altText="Iceland Guided Tours" website="https://igtours.is" />
                <Customer imgSrc="/img/bigescapelogo.svg" altText="The Big Escape" website="https://thebigescape.com" />
                <Customer imgSrc="/img/nicetravel.png" altText="Nice Travel" website="https://nicetravel.is" />

                <Customer imgSrc="/img/beasalocal.png" altText="Be As a local" website="https://beasalocal.com" />
                <Customer imgSrc="/img/yourdaytours.jpg" altText="Your Day tours" website="https://yourdaytours.is" />
                <Customer imgSrc="/img/whale-safari.png" altText="Whale Safari" website="https://whalesafari.is" />  
                <Customer imgSrc="/img/visit-crete.png" altText="Visit Crete" website="https://www.visitcrete.co.uk" />
                
                <Customer imgSrc="/img/kruger-to-cape.png" altText="Kruger to Cape" website="https://activities.krugertocape.co.za" />
                <Customer imgSrc="/img/travelcheck.png" altText="Travel Check" website="https://experiences.travelcheck.co.za" />
                <Customer imgSrc="/img/glorious-cape-tours.png" altText="Glorious Cape Tours" website="https://gloriouscapetours.co.za" />
                <Customer imgSrc="/img/privatebelize.png" altText="Private Belize Adventures" website="https://www.privatebelizeadventures.com" />

            </div>

            <hr />
            <blockquote>
              "Working with Getlocal was a pleasure. I appreciated their professionalism, their precision and responsiveness. The team helped us to build a great website with online booking. It makes our life easier every day. Even after putting the site online they remain at call and are very responsive and helpful to all my questions / requests."
              <br /><br />
              — Adrien Albrecht, Chief Operating Officer, <a href="https://inspiration-iceland.com" target="_blank"  rel="noopener noreferrer">Inspiration Iceland</a>
            </blockquote>
            <hr />

            <div className="columns is-multiline is-vcentered">
                <Customer imgSrc="/img/endless-weekend.png" altText="Endless Weekend" website="https://endlessweekend.com" />
                <Customer imgSrc="/img/daytrip4u.png" altText="Samba Tours" website="https://samba.tours" />

                <Customer imgSrc="/img/icelandbybus.png" altText="Iceland By Bus" website="https://icelandbybus.is" /> 
                <Customer imgSrc="/img/mrpuffin.jpg" altText="Mr Puffin" website="https://puffintours.is" />

                
                <Customer imgSrc="/img/visit-cyprus.png" altText="Visit Cyprus" website="https://www.visitcyprus.co.uk" />

                <Customer imgSrc="/img/icelandhorizon.png" altText="Iceland Horizon" website="https://icelandhorizon.is" />
                <Customer imgSrc="/img/nl.png" altText="Northern Lights Bus" website="https://northernlightsbus.is" />
                <Customer imgSrc="/img/solhestar.png" altText="Sólhestar" website="https://solhestar.is" />
                <Customer imgSrc="/img/sterna.png" altText="Sterna Travel" website="https://sternatravel.com" />
                
                <Customer imgSrc="/img/private.png" altText="Private Excursions" website="https://privateiceland.is" />
                <Customer imgSrc="/img/reykjavikerupts.png" altText="Reykjavik Erupts" website="https://reykjavikerupts.is" />
                <Customer imgSrc="/img/inspiration-iceland.jpg" altText="Inspiration Iceland" website="https://inspiration-iceland.com" />
                <Customer imgSrc="/img/things2do.png" altText="Things 2 Do" website="https://things2do.eu" />
                        
                <Customer imgSrc="/img/laugavegur.png" altText="Laugavegur Trail" website="https://laugavegur.is" />
                
                <Customer imgSrc="/img/icelandintro.png" altText="Iceland Intro" website="https://icelandintro.is" />    
                <Customer imgSrc="/img/smartbus.png" altText="Smart Bus" website="https://smartbus.is" />
                <Customer imgSrc="/img/grapevine.png" altText="GoTravel by Grapevine" website="https://gotravel.grapevine.is" />
                <Customer imgSrc="/img/icelandexcursions.png" altText="Iceland Excursions" website="https://icelandexcursions.is" />
                
                
                
            </div>

          </div>
        </div>
    
    </section>
  </Layout>
)
